<script setup>
    import PrimevueInputText from 'primevue/inputtext';
    import PrimevueButton from 'primevue/button';


    const { $api, $me } = useNuxtApp();
    const me = $me.get();

    // Initialize i18n
    const { localeProperties, t: $t } = useI18n();
    const locale = computed(() => localeProperties.value.language);

    // Props definition
    const props = defineProps({
        page: {
            type: Object,
            required: true
        }
    });

    // Data definition
    const email = ref(me?.email);
    const emailError = ref(false);

    /**
     * Send subscription to API
     */
    const sendEmail = async () => {
        if (! email.value) {
            emailError.value = true;
            return;
        }

        emailError.value = false;

        $api.post('v1/stay-informed', {
            body: {
                user_id: me?.id,
                page_id: props.page?.id,
                email: email.value,
                current_url: useRoute().path,
                locale: locale.value
            }
        });
    };
</script>

<template>
    <div class="message grid-full-row m-0 gap-3">
        <b class="text-xl">
            <!-- Oups, on a tout vendu ! -->
            {{ $t('Oups, we sold everything !') }}
        </b>

        <!-- Restez informé des prochaines ventes Carrefour -->
        {{ $t('Stay informed of the next sales') }} {{ props.page.breadcrumb_title[locale] }}

        <div class="flex flex-wrap gap-2 justify-content-around">
            <PrimevueInputText
                type="email"
                placeholder="email"
                v-model="email"
                class="flex-1"
                :class="{'p-invalid': emailError}"
            />

            <PrimevueButton
                severity="success"
                type="button"
                :label="$t('Receive the alert')"
                icon="pi pi-check"
                @click.prevent="sendEmail"
            />
        </div>
    </div>
</template>
